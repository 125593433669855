<template>
  <div class="information-center-main">
    <div class="information-center-content">

      <v-row>
        <v-col cols="auto">
          <v-icon size="42">article</v-icon>
        </v-col>
        <v-col>
          <h1 class="display-1">{{ $t('main.INFOTHEK') }}</h1>
        </v-col>
        <v-col
          cols="auto"
          sm="4"
        >
          <v-text-field
            v-model="search"
            solo
            dense
            prepend-inner-icon="search"
            :placeholder="$t('main.SEARCH')"
          />
        </v-col>
      </v-row>

      <v-tabs
        v-model="tab"
        class="gm-tabs"
      >
        <v-tab v-for="infoCat in infoCategories" :key="infoCat.value">{{ infoCat.text }}</v-tab>
      </v-tabs>

      <div class="information-center-list">
        <div v-if="loading">
          <v-skeleton-loader type="avatar" />
          <v-skeleton-loader type="list-item-three-line" />
          <v-skeleton-loader type="list-item-three-line" />
        </div>

        <div
          v-for="letterData in contentByLetter"
          :key="letterData.letter"
          class="mb-10"
        >
          <h2 class="title mb-2">{{ letterData.letter }}</h2>

          <v-row dense>
            <v-col
              v-for="entry in sortByName(letterData.entries)"
              :key="entry.id"
              cols="12"
              sm="6"
              md="4"
            >
              <a :href="entry.link" target="_blank" class="text-decoration-none">
                {{ entry.name }}
              </a>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: 'InformationCenter',
  data() {
    return {
      loading: false,
      search: '',
      tab: 0,
      infoCategories: [
        { value: "ALL", text: this.$t('main.SHOW_ALL') },
        { value: "TOUROPERATOR", text: this.$t('main.TOUROPERATOR') },
        { value: "AIRLINE", text: this.$t('main.AIRLINE') },
        { value: "AIRPORT", text: this.$t('main.AIRPORT') },
        { value: "COUNTRY", text: this.$t('main.COUNTRY') },
      ]
    }
  },
  mounted() {
    this.loading = true
    this.touroperatorExternalRestrictionInfosFetchAll().then(() => {
      this.loading = false
    })
  },
  computed: {
    ...mapState({
      language: state => state.uiStore.language,
      touroperatorExternalRestrictionInfos: state => state.touroperatorExternalRestrictionInfosStore.entries,
      touroperatorExternalRestrictionInfosIsDone: state => state.touroperatorExternalRestrictionInfosStore.isDone,
    }),
    infoCategory() {
      return this.infoCategories[this.tab].value
    },
    contentByLetter() {
      // filter by name
      let entries = this.touroperatorExternalRestrictionInfos.filter((to) => to.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1)

      // filter by category

      if (this.infoCategory != 'ALL') {
        entries = entries.filter((to) => to.infoCategory == this.infoCategory)
      }

      // filter entries by link = a link in the current language must be available
      entries = entries.filter((to) => {
        if (this.language == 'de' && to.linkDe ) {
          return true
        }
        if (this.language == 'en' && to.linkEn ) {
          return true
        }
        return false
      })


      // group by touroperator first letter
      const obj = entries.reduce((acc, c) => {
        const letter = c.name[0];
        acc[letter] = (acc[letter] || []).concat(c);
       return acc;
      }, {})

      // `map` over the object entries to return an array of objects
      let result = Object.entries(obj).map(([letter, entries]) => {
        return { letter, entries }
      }).sort((a, b) => a.letter.localeCompare(b.letter));

      return result
    }
  },
  methods: {
    ...mapActions({
      touroperatorExternalRestrictionInfosFetchAll: 'touroperatorExternalRestrictionInfosStore/fetchAll'
    }),
    openWindow(touroperator) {
      window.open(touroperator.link, '_blank', 'width=1300')
    },
    sortByName(touroperators) {
      return touroperators = touroperators.sort((a,b) => a.name.localeCompare(b.name));
    }
},
  watch: {
  }
}
</script>

<style scoped>
.information-center-main {
  display: flex;
  padding: 2rem;
  height: 100%;
  justify-content: center;
  /*background-color: #ebeaea;*/
  background-color: #f7f7f7;
}

.information-center-content {
  max-width: 1200px;
  width: 1200px;
}

.information-center-list {
  padding: 32px;
  background-color: white;
}
</style>
